/*
* normalize
*/
@import "../../bower_components/normalize.css/normalize.css";

/*
* flaticon
*/
@import "partials/_flaticon";

@import 'https://fonts.googleapis.com/css?family=Roboto+Condensed|Roboto+Slab|Roboto:100,300,400,700,900';

/*
* custom
*/
@import "partials/variable";

/*
* custom
*/
@import "partials/responsive";