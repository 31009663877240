@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-back:before { content: "\f100"; }
.flaticon-clipboard-verification-symbol:before { content: "\f101"; }
.flaticon-expand:before { content: "\f102"; }
.flaticon-facebook-logo:before { content: "\f103"; }
.flaticon-google-plus-logo:before { content: "\f104"; }
.flaticon-heart-outline:before { content: "\f105"; }
.flaticon-next:before { content: "\f106"; }
.flaticon-pinterest:before { content: "\f107"; }
.flaticon-social:before { content: "\f108"; }
.flaticon-tool:before { content: "\f109"; }
.flaticon-triangular-arrows-sign-for-recycle:before { content: "\f10a"; }
.flaticon-trolley:before { content: "\f10b"; }
.flaticon-twitter-logo-silhouette:before { content: "\f10c"; }
.flaticon-web:before { content: "\f10d"; }