$main-color: #45413e;
$link-color: #816fca;
$link-color-hover: #816fca/2;
$background-light: #fff;
$background-dark: #f1edea;
$container-width: 1170px;

$border-color: #e8e2d6;

$main-font-size: 16px;
$main-line-height: 24px;
$main-font-weight: 300;

$roboto: 'Roboto';
$robotoSlab: 'Roboto Slab';
$robotoCondensed: 'Roboto Condensed';

@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -khtml-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

@mixin border-radius($radius: 5px) {
  @include css3-prefix('border-radius', $radius);
}

:focus{
  @include css3-prefix('outline','none');
}

:hover{
  @include css3-prefix('transition-duration', '0.5s');
}

body{
  background: $background-dark;
  font-family: $roboto;
  color: $main-color;
}

h1{
  text-align: center;
  font-size: 48px;
  font-family: $robotoCondensed;
  font-weight: 700;
  margin: 17px 0;
}

p{
  font-size: $main-font-size;
  line-height: $main-line-height;
  font-weight: $main-font-weight;
}

a{
  color: $link-color;
  text-decoration: none;
}
a:hover{
  color: $link-color-hover;
}

ul{
  list-style: none;
  margin: 0;
  padding: 0;
}

.fLeft{float: left}
.fRight{float: right}

.dNone{display: none}
.dBlock{display: block}

.container{
  max-width: $container-width;
  margin: 0 auto;
  padding: 0 30px;
  @extend .row
}
.row:before,
.row:after{
  clear: both;
  display: table;
  content: '';
}

.header{
  background: $background-light;
  height: 78px;

  .s-l-w-c{
    float: right;
    margin-top: 25px;
    margin-right: 10px;
  }
}

a.logo{
  text-transform: uppercase;
  font-size: 30px;
  font-family: $robotoSlab;
  font-weight: 700;
  line-height: 78px;
  margin-right: 6.155%;
}

ul.menu{
  margin-right: 5px;

  & > li{
    float: left;
    margin: 0 18px;
  }
  & > li > a{
    display: block;
    padding: 30px 25px;
    color: #45413e;
    text-transform: uppercase;
    font-family: $roboto;
    font-size:16px;
    font-weight: 900;
  }
  .active a,
  a:hover{
    color: #fff;
    background: #262a32;
  }
  .has-children{position: relative}
  & > li.has-children > a:after{
    content: '';
    display: block;
    border: 3px solid transparent;
    border-top: 3px solid #d1d5d8;
    position: absolute;
    right: 0;
    top: 37px;
  }
  .has-children.active > a:after,
  .has-children > a:hover::after{display: none;}
}
.loadCategories{}

.sub-menu{
  width: 100%;
  position: absolute;
  top: 78px;
  left: 0;
  z-index: 101;
  background: #262a32;
  padding: 53px 0 43px 0;
  display: none;

  .title{
    color: #fff;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 14px;
    margin: 0 0 15px 0;
  }

  .container{
    div{float: left;}
  }

  ul{float: left;}
  li{
    line-height: 31px;
  }
  a{
    color: #fff;
  }

  .productCategories{
    ul{
      width: 28%;
      padding-right: 3.72%;
    }
  }
  .sale{
    ul{
      width: 41.5%;
      padding-right: 5.74%;
    }
  }

  .productCategories{width: 59.82%}
  .sale{width: 40.17%}
}

.lang{
  margin: 26px 0 0 25px;
  color: #45413e;
  font-size: 14px;
  font-family: $roboto;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0.5;
  float: left;
  position: relative;

  ul{
    border: 2px solid transparent;
    @include border-radius(11px);
  }
  ul:after{
    content: '';
    display: block;
    border: 3px solid transparent;
    border-top: 6px solid #d1d5d8;
    position: absolute;
    right: 8px;
    top: 10px;
  }
  li{
    display: none;
    padding: 4px 24px 4px 10px;
    @include border-radius(10px);
  }
  li:hover{
    background: #eee;
    cursor: pointer;
  }
  li.active{display: block;}
}

.lang:hover{
  opacity: 1;
  ul{border: 2px solid $border-color; background: #fff}
  li{display: block}
}

.s-l-w-c{
  float: right;
  margin-top: 25px;
  margin-right: 12px;

  li{
    float: left;
    width: 60px;
    text-align: right;
    position: relative;
  }
  li:first-child{width: auto}

  a{
    color: #a2a09e;
    font-size: 22px;
  }

  a:hover,
  a:hover span.count{color: $link-color-hover};
  a:hover span.count{border: 2px solid $link-color-hover;}

  span.count{
    position: absolute;
    top: -10px;
    right: -9px;
    display: block;
    width: 14px;
    height: 14px;
    border: 2px solid $link-color;
    @include border-radius(50%);
    text-align: center;
    font-size: 12px;
    line-height: 15px;
    color: $link-color;
    background: $background-light;
    font-weight: 900;
  }
}

.breadcrumbs{
  font-weight: 300;

  ul{
    text-align: center;
  }
  li{
    display: inline-block;
  }
  li:after{
    float: right;
    content: '/';
    display: block;
    margin: 0 19px;
  }
  li:last-child:after{
    display: none;
  }
}

.main{
  padding: 55px 0 117px 0;
}

.product{
  background: $background-light;
  @include border-radius();
  width: 100%;
}

#slider{
  width: 59.82%;
}
.image-wrapper{
  width: 85.71%;
  height: 568px;
  overflow: hidden;

  img{
    width: 100%;
    @include border-radius('5px 0 0 0');
  }
}
.image-wrapper,
#thumbs{line-height: 0;}

#thumbs{
  float: right;
  width: 73px;

  img{
    width: 100%;
    height: auto;
    margin-top: 25px;
    opacity: 0.5;
    @include css3-prefix('transition', '.2s ease-in-out');
  }
  img:hover{
    cursor: pointer;
    opacity: 1;
  }
  .active{opacity: 1;}
}

.product-value{
  width: 30.34%;
  padding: 40px 45px 0 0;

  .price{
    font-family: $robotoSlab;
    font-size: 48px;
    font-weight: 700;
  }

  .colors{
    margin:37px 0 20px 0;
    height: 40px;
    float: left;
    position: relative;
    z-index: 100;

    ul{
      background: $background-light;
      @include border-radius('20px');
      border: 2px solid #e8e2d6;
    }
    ul:after{
      content: '';
      display: block;
      border: 3px solid transparent;
      border-top: 6px solid $border-color;
      position: absolute;
      top: 18px;
      right: 20px;
    }

    li{
      display: none;
      font-weight: 400;
      line-height: 22px;
      position: relative;
      padding: 7px 40px;
      cursor: pointer;
      @include border-radius('20px');
    }
    li:hover{background: #fafafa}
    li:before{
      position: absolute;
      left: 6px;
      content: '';
      display: block;
      width: 22px;
      height: 22px;
      @include border-radius('50%');
    }
    li.active{
      display: block;
    }
    li.darkGray:before{background: #585d61;}
    li.black:before{background: black;}
    li.red:before{background: red;}
  }
  .colors:hover{
    li{display: block}

    .active{background: #eee}
  }

  .quantity{
    position: relative;
    width: 40px;
    border: 2px solid $border-color;
    padding: 8px 33px;
    @include border-radius('20px');

    #quantity{
      font-size: 14px;
      color: $main-color;
      text-align: center;
      width: 40px;
      border: none;
      line-height: 18px;
    }
    button{
      position: absolute;
      top: 50%;
      margin-top: -11px;
      font-size: 18px;
      font-weight: 700;
      color: #999;
      border: none;
      width: 22px;
      cursor: pointer;
      @include border-radius(50%);
      background: none;
    }
    button.less{
      left: 10px;
    }
    button.more{
      right: 10px;
    }
  }

  .sortValue{
    padding-top: 27px;

    p{margin-bottom: 10px;}
  }

  .buy{
    background: $link-color;
    text-transform: uppercase;
    color: #fff;
    font-size: 14px;
    @include border-radius(20px);
    border: none;
    font-weight: 900;
    cursor: pointer;
    padding: 12px 34px;
    margin-left: 20px;
  }
  .buy:hover{background: $link-color-hover;}

  .readMore{
    font-weight: 900;
    font-size: 16px;
  }

  .share{
    margin-top: 28px;

    span{
      font-size: 14px;
      line-height: 17px;
    }
  }

  .shareButtons{

    li{
      float: left;
      margin-left: 20px;

      [class*=" flaticon-"]:after,
      [class*=" flaticon-"]:before,
      [class^=flaticon-]:after,
      [class^=flaticon-]:before{
        font-size: 17px;
        color: #b2b2b2;
      }
    }
    [class*=" flaticon-"]:hover::after,
    [class*=" flaticon-"]:hover::before,
    [class^=flaticon-]:hover::after,
    [class^=flaticon-]:hover::before{
      color:$link-color-hover;
      @include css3-prefix('transition-duration', '0.5s');
    }
  }
}



.tabs{
  margin-top: 48px;
  text-align: center;
  border-bottom: 2px solid $border-color;

  li{
    display: inline-block;
    font-family: $robotoCondensed;
    font-weight: 700;
    font-size: 27px;
    color: #bdc3c7;
    padding: 18px 49px;
    margin-bottom: -2px;
    cursor: pointer;
    border: 2px solid transparent;
  }
  li.active{
    color: #000;
    border: 2px solid $border-color;
    border-bottom: 2px solid #fff;
    @include border-radius('20px 20px 0 0');
  }
}

.tab-content{
  width: 88.03%;
  margin: 0 auto;
  padding: 58px 0;
  overflow: hidden;

  p{
    color: #555;
    margin: 12px 0;
  }

  ul{@extend .row}

  li{
    width: 44.757%;
    margin-right: 5.2%;
    float: left;
  }

  #wrapper{
    @extend .row;
    width: 3090px;
    position: relative;
  }

  div{
    div{
      float: left;
      width: 1030px;
      margin: 0 auto;
    }
  }
}

.popularItems{
  margin-top: 43px;
  text-align: center;
  overflow: hidden;

  .title {
    font-size: 26px;
    font-weight: 700;
    font-family: $robotoCondensed;
    text-transform: uppercase;
  }
  .name{
    color: #2c3e50;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
  }
  .price{
    color: #bdc3c7;
    font-family: $robotoSlab;
    font-size: 17px;
    font-weight: 700;
  }
  span{
    display: block;
    padding: 7px 15px;
    @extend .row;
  }

  #carousel{
    margin-top: 17px;
    position: relative;

    .goLeft,
    .goRight{
      position: absolute;
      top: -47px;
      cursor: pointer;
      font-size: 28px;
      color: #8d8d8c;
    }
    .goLeft{left: 0}
    .goRight{right: 0}
  }
  #carousel-wrapper{
    position: relative;
    width: 1800px;

    li{
      float: left;
      @include border-radius(5px);
      background: $background-light;
      width: 270px;
      margin-right: 30px;
      padding-bottom: 12px;
    }
    .img{
      width: 100%;
      //height: 261px;
      overflow: hidden;
      margin-bottom: 5px;
    }
    img{
      @include css3-prefix('transition', '.5s ease-in-out');
      width: 100%;
      height: auto;
    }
    li a:hover{
      span.name{
        color: $link-color-hover;
      };

      img{
        @include border-radius(5px);
        @include css3-prefix('transform', 'scale(1.1) rotate(-1deg)');
        opacity: 0.85;
      }
    }
  }
}

.footerTop{
  background: #45413e;
  padding: 46px 0;
  @extend .row;

  div{
    div{
      position: relative;
      float: left;
      width: 20.55%;
      padding-left: 8.63%;
      margin-right: 65px;
    }
    div:last-child{margin-right: 0}
  }
  div:before {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -34px;
    font-size: 60px;
    color: #816fca;
    margin-left: 0;
    font-family: "Flaticon";
  }
  div.delivery:before{
    content: "\f10b";
  }
  div.return:before{
    content: "\f10a";
  }
  div.choice:before{
    content: "\f101";
  }

  p.title{
    color: #fff;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  p{
    font-family: $roboto;
    color: #868380;
    font-weight: 400;
    margin: 0;
    line-height: 18px;
  }
}

.footer{
  padding-top: 40px;
  margin-bottom: 29px;

  .container{
    div{
      display: inline-block;
      vertical-align: top;
    }
    .copyRight{
      width: 270px;

      p{
        font-size: 13px;
        line-height: 18px;
        margin: 0;
      }
    }
    .title{
      color: #333;
      font-size: 13px;
      line-height: 13px;
      font-weight: 900;
      text-transform: uppercase;
      margin: 0 0 23px 0;
    }
    ul{
      float: left;
      padding-right: 30px;
      width: 170px;

      li{
        margin-bottom: 13px;
      }
      a{
        color: $main-color;
        font-size: 13px;
      }
      a:hover{
        color: $link-color-hover;
      }
    }
    .footerMenuAbout{
      ul{
        width: 160px;
        padding-right: 0;
      }
    }
    .shareButtons{
      padding: 0;
      width: auto;

      li:first-child{margin: 0}

      li{
        float: left;
        margin-left: 20px;
      }
    }
  }
}