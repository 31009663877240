@media (max-width:1250px){
  .header{
    position: relative;

    .container{text-align: center}
    .langs{
      position: absolute;
      top: 49px;
      right: 30px;
      z-index: 2;
    }
    .s-l-w-c{
      position: absolute;
      top: 5px;
      right: 30px;
      z-index: 1;
      margin-top: 15px;
    }
  }
  .sMenu{
    ul{
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .s-l-w-c li{width: 40px;}
  .lang{
    margin-left: 0;
    margin-top: 2px;
  }
  h1{font-size: 40px;}

  #slider{
    width: 320px;

    .image-wrapper{
      height: 303px;
      width: 100%;
    }
  }
  #thumbs{
    float: left;
    width: 100%;
    margin-top: 20px;
    
    img{
      margin: 0px 9px;
      width: auto;
      height: 40px;
    }
  }
  .product-value{
    width: calc(100% - 390px);
    padding-top: 10px;

    .price{font-size: 30px;}
    .colors{margin: 16px 20px 0 0;}
    .row .row{float: left;}
    .quantity{margin: 16px 20px 0 0;}
    .addToCart{
      margin-top: 16px;
      .buy{margin-left: 0;}
    }
    .sortValue{padding: 0;}
    .share{margin-top: 10px;}
  }

  .tabs{
    li{
      font-size: 20px;
      padding: 15px 20px;
    }
  }

  .footerTop{
    div{
      text-align: center;
      div{
        display: inline-block;
        float: none;
        margin: 0 10px;
        vertical-align: top;
        padding-left: 70px;
      }
      p{text-align: left;}
    }
  }

  .footer{
    .container{
      div{
        display: block;
        float: left;
      }
      .copyRight,
      .footerShare{
        width: 20%;
        float: right;
      }
      .footerShare{margin-top: 15px;}
      .footerMenus{width: 80%;}
      .footerMenu{
        width: 80%;

        ul{
          width: 30%;
          padding-right: 3%;
        }
      }
      .footerMenuAbout{
        width: 20%;

        ul{width: 100%;}
      }
    }
  }

}

@media (max-width:1000px){
  ul.menu>li{margin: 0 5px;}
  ul.menu>li>a{padding: 30px 15px;}
}
@media (max-width:925px){
  .footerTop{
    div{
      div{
        display: block;
        width: 75%;
        min-width: 190px;
      }
      div,
      div:last-child{margin: 20px auto 0 auto;}
      div:first-child{margin-top: 0;}
    }
    p.title{margin-bottom: 10px;}
  }
}

@media (max-width:850px){
  ul.menu {
    width: 150px;
    margin: 0 auto;
    float: none;

    li{width: 100%;}
    li > a {padding: 4px 15px;}
    li.has-children>a:after{top:12px;}
  }
}

@media (max-width:768px){
  h1{font-size: 22px;}

  .container{padding: 0 15px;}

  .s-l-w-c li {width: 25px;}
  .s-l-w-c a {font-size: 15px;}
  .s-l-w-c span.count {
    width: 11px;
    height: 11px;;
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
  }
  .langs{right:20px}
  .lang li {padding: 4px 14px 4px 5px;}
  .lang ul:after {right: 4px;}

  #slider{
    width: 290px;
    margin: 0 auto;
    float: none;
    .image-wrapper{height: 275px;}
  }
  #slider:after{
    clear: both;
    display: table;
    content: '';
  }
  #thumbs img {margin: 0 5px;}

  .product-value{
    float: none;
    width: 90%;
    margin: 0 auto;
    padding:30px 10px 0 10px;

    & > .row{text-align: center;}
    .quantity{margin-left: 0;}
  }
  .product-value .colors,
  .product-value .quantity,
  .product-value .addToCart{
    display: inline-block;
    float: none;
    margin: 10px;
  }
  .footer{
    .container{
      ul{float: none;}
      .footerMenus{
        div{
          width: 50%;
          ul{
            width: 90%;
            padding-right: 5%;
          }
        }
      }
    }
  }
}

@media (max-width:660px){
  a.logo{font-size: 24px}
  .footer .container .copyRight,
  .footer .container .footerShare{
    width: 100%;
    float: left;
  }
  .copyRight{margin-bottom: 15px;}
  .footer .container .footerMenus{width: 100%;}
  .footer .container .footerMenus div{
    width: 45%;
    padding-right: 5%;
  }
}

@media (max-width:660px){
  .sub-menu{padding: 20px 0}
  .sub-menu .productCategories ul{width: 46%;}
  .sub-menu .productCategories,
  .sub-menu .sale{ width: 100% }
  .sub-menu .title{margin: 0; text-align: center}
  .sub-menu .sale .title{margin-top: 15px}
}

@media (max-width:540px){
  a.logo{
    text-align: left;
    display: block;
    float: none;
  }
  ul.menu{
    margin-top: 14px;
    width: 100%;

    li{
      width: auto;
      display: inline-block;
      float: none;
      margin: 0;

      a {padding: 8px 10px;}
    }
    li.has-children>a:after{top: 14px;}
  }
  ul.menu:before,
  ul.menu:after{
    clear: both;
    display: table;
    content: '';
  }
  .sub-menu{top: 126px;}
}